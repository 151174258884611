/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@import "../../@core/scss/base/bootstrap-extended/include";
@import "../../@core/scss/base/components/include";
.data-list header {
  display: block;
  background: transparent;
  padding: 0;
}

.data-list header .add-new-btn {
  padding: 0.9rem 0.938rem;
}
// .Custom_Cancel_Sweet > .swal2-actions > .swal2-cancel .swal2-styled {
//   margin-left: 10px;
// }
.swal2-cancel {
  margin-left: 10px !important;

}

.data-list header .sort-dropdown {
  padding: 1.02rem 0.65rem !important;
  border-radius: 5rem;
}

.data-list header .filter-section {
  position: relative;
}

.data-list header .filter-section input {
  padding: 1.45rem 0rem;
  padding-left: 2.8rem;
  border-radius: 1.428rem;
  max-width: 200px;
}

.data-list header .filter-section:after {
  content: "\e8bd";
  font-family: feather;
  position: absolute;
  left: 1rem;
  top: 0.85rem;
}

.data-list .rdt_Table {
  background-color: transparent;
  padding: 0;
  margin-top: 1rem;
}

// .data-list .rdt_Table .rdt_TableCol:first-child,
// .data-list .rdt_Table .rdt_TableCell:first-child {
//   flex: 0 0 80px;
// }

.data-list .rdt_Table .rdt_TableHead .rdt_TableHeadRow {
  background-color: transparent;
  border: 0;
  min-height: 61px;
}

.data-list .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
  font-size: 0.85rem;
  font-weight: 600;
  text-transform: uppercase;
}

.borderRed{
border: 1px solid #ea5455;
border-radius: .357rem;
  // border-color: #ea5455;
  background-image: url(../images/icons/invalid.svg);
  background-repeat: no-repeat;
  background-size: calc(0.725em + 0.438rem) calc(0.725em + 0.438rem);
}

.data-list
  .rdt_Table
  .rdt_TableHead
  .rdt_TableHeadRow
  .rdt_TableCol
  .rdt_TableCol_Sortable
  svg {
  height: 15px !important;
  width: 15px !important;
  margin-left: 0.5rem;
}

.data-list .rdt_Table .rdt_TableBody .rdt_TableRow {
  min-height: 60px;
  margin-bottom: 8px;
  border: 0;
  border-radius: 0.5rem;
  //transition: all 0.3s ease;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
  font-size: 1rem;
  font-weight: 500;
}
.backgroundWhite{
  width: 100%;
  color: #7367f0 !important;
  background-color: #FFFFFF !important;
  border: white;
  font-weight: 500 !important;
  font-size: 1.1rem !important;
}

.btn-secondary:focus {
    background-color: #FFFFFF !important;
}
// .data-list .rdt_Table .rdt_TableBody .rdt_TableRow:hover {
//   //transform: translateY(-4px);
// }
.input-group-text{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0.4rem 1rem;
  margin-right:-1px
}
// [dir] .input-group-text {
//   padding: 0.43rem 1rem;
//   margin-bottom: 0;
//   text-align: center;
//   background-color: white;
//   border: 1px solid #d8d6de;
//   border-radius: 0.357rem;
// }

.main-menu.menu-light .navigation li a {
  color: #867df8 !important;
  margin: 0 5px 0 10px !important;
  font:normal normal 600 15px 'Poppins';
}

.main-menu.menu-light .navigation li.active a {
  color: white !important;
}

#inputSearchID::placeholder {
  color: #0F0A40;
  font-weight: 500;
}
#inputSearchID{
color: #0F0A40;
font-weight: 500;
}
.rowTitle{
  color: #0F0A40;
  font-weight:bold;
}
.rowEducation{
  font-size: 14px;
  color: #0F0A40;
  font-weight: medium;
}
.rowPhoneNumber{
  font-size: 15px;
  color: #0F0A40;
  opacity: 0.7;
  font-weight: 500;
}
.rowPost{
  color: #0F0A40;
  font-weight:600;
}
.inputGroupSearch {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  color: #0F0A40;
  border-radius: .357rem;
}

.data-list .rdt_Table ~ .rdt_Pagination {
  border: 0;
  background-color: transparent;
}

.data-list.thumb-view .rdt_Table .rdt_TableBody .rdt_TableRow {
  min-height: 120px;
}

.data-list .vx-pagination {
  min-height: 34px;
}

.data-list .vx-pagination li.disabled {
  opacity: 0.5;
}

.data-list .data-list-sidebar {
  width: 28.57rem;
  max-width: 90vw;
  height: 100vh;
  position: fixed;
  left: auto;
  right: 0;
  top: 0;
  z-index: 1033;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  transition: all 0.25s ease;
  transform: translateX(100%);
}

.data-list .data-list-sidebar.show {
  transform: translateX(0%);
}

.data-list .data-list-sidebar .data-list-sidebar-header {
  padding-bottom: 0.714rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.data-list .data-list-sidebar .data-list-fields {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  height: calc(100% - 9rem);
  position: relative;
}

.data-list .data-list-overlay {
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  display: none;
  z-index: 1032;
  transition: all 0.3s ease;
}

.data-list .data-list-overlay.show {
  opacity: 1;
  display: block;
}

@media (max-width: 485px) {
  .data-list header .filter-section,
  .data-list header .actions-right {
    width: 100%;
  }
  .data-list header .filter-section input,
  .data-list header .actions-right input {
    max-width: none;
  }
}

.vx-pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.5rem;
  /***** Styles *****/
  /***** Positions *****/
  /***** Sizes *****/
}

.vx-pagination li {
  display: inline-block;
  user-select: none;
}

.vx-pagination li a {
  padding: 0.65rem 0.911rem;
  border: none;
  margin-left: 0;
  font-size: 1rem;
  font-weight: 700;
  display: block;
  line-height: 1.25;
  width: 100%;
}

.vx-pagination li.active a {
  border-radius: 0.5rem;
  background-color: $primary;
  color: #ffffff;
  transform: scale(1.05);
}

.vx-pagination li.disabled a {
  color: rgba(0, 0, 0, 0.25);
}

.vx-pagination li:not(.disabled):not(.active):hover a {
  color: $primary;
}

.vx-pagination li:first-child {
  border-top-left-radius: 1.428rem;
  border-bottom-left-radius: 1.428rem;
}

.vx-pagination li:last-child {
  border-top-right-radius: 1.428rem;
  border-bottom-right-radius: 1.428rem;
}

.vx-pagination.basic-pagination .previous,
.vx-pagination.basic-pagination .next {
  display: none;
}

.vx-pagination.seprated-pagination .previous,
.vx-pagination.seprated-pagination .next {
  border-radius: 50%;
}

.vx-pagination.seprated-pagination .previous a,
.vx-pagination.seprated-pagination .next a {
  padding: 0.572rem 0.7rem;
}

.vx-pagination.seprated-pagination .previous {
  margin-right: 0.3571rem;
}

.vx-pagination.seprated-pagination .next {
  margin-left: 0.3571rem;
}

.vx-pagination.pagination-start {
  justify-content: flex-start;
}

.vx-pagination.pagination-center {
  justify-content: center;
}

.vx-pagination.pagination-end {
  justify-content: flex-end;
}

.vx-pagination.pagination-lg li a {
  font-size: 1.5rem;
}

.vx-pagination.pagination-lg li.active a {
  transform: scale(1);
}

.vx-pagination.pagination-sm li a {
  padding: 0.5rem 0.75rem;
}

.vx-pagination.pagination-sm li.active a {
  transform: scale(1.05);
}

.data-list-header {
  z-index: 1;
}
//Modal css
.Modal {
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 3px 10px 0 rgba(63, 63, 68, 0.24);
  box-sizing: border-box;
  height: 345px;
  padding: 14px;
  position: absolute;
  top: 97px;
  width: 291px;
}

.Overlay {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;
}

.ReactModal__Overlay {
  background-color: none;
  transition: background-color 0.2s;
}

.ReactModal__Overlay--after-open {
  background-color: rgba(61, 81, 91, 0.3);
}

.ReactModal__Overlay--before-close {
  background-color: unset;
}

.custom-control {
  z-index: 0;
}
.file-extension {
  font-weight: 700;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 58%;
}
// .boxshadowBlue:hover{
// box-shadow: 0 8px 25px -8px #a199ee
// }
.uploaded-files {
  .progress,
  .progress-bar {
    height: 5px;
  }
}

input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
.dropdown-item:focus {
  background-color: white;
}

.uppy-FileInput-container {
  margin-bottom: 0 !important;
}
.uppy-FileInput-btn {
  &:hover {
    border: 1px solid #0bb197 !important;
    background: #0bb197 !important;
    color: #ffffff !important;
  }
  height: 47px;
  font-size: 24px !important;
  border: 1px solid #0bb197 !important;
  color: #0bb197 !important;
}
// body {
//   font-family: "Poppins"!important;
//   font-weight: 600!important;
//   font-style: normal!important;
//   font-variant: normal!important;
// }

.data-list .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
}
.demo-editor {
  min-height: 275px !important
  ;
}
.Toastify__toast .Toastify__toast-body, .Toastify__toast .Toastify__close-button {
  color: white;
}
Toastify__toast .Toastify__close-button svg {
  height: 0.85rem;
  width: 0.85rem;
  fill: white;
}
.rdw-editor-main {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  border: 1px solid #f1f1f1 !important;
}
.brand-text{
  padding-left: 0 !important;
}
.Toastify__toast .Toastify__close-button svg,.swal2-close  {
  display: none !important; 
}
[dir] .rdw-editor-main {
  border: 1px solid #f1f1f1 !important;
  padding: 6px !important;
}
.noDataComponent{
  background-color: #f8f8f8 !important;
  font-size: 16px !important;
}
.rdt_Table > div{
  padding: 10px;
  font-size: 16px !important;
}
.rdt_TableBody{
  padding: 0px !important;
}
.rdt_TableHead{
  padding: 0px !important;
}
// .auth-wrapper.auth-basic .auth-inner {
//   max-width: 400px;
// }
.auth-wrapper.auth-basic {
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth-wrapper {
  display: flex;
  flex-basis: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh)*100);
  width: 100%;
}

// .form-control.is-invalid {
//   border-color: #ea5455;
//   background-image: none !important;
//   background-repeat: no-repeat;
//   background-size: calc(0.725em + 0.438rem) calc(0.725em + 0.438rem);
// }
// [dir] .text-center {
//   text-align: left !important;
// }